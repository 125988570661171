@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

:root {
  /* cool */
  --color-primary: 64 70 92;
  --color-secondary: 255 255 255;
  --color-trans: rgba(255, 255, 255, 0.6);
  --color-tertiary: 232 232 232;
  --color-heading: 64 70 92;
  --color-title: 64 70 92;
  --color-typography-1: 30 30 30;
  --color-typography-2: 232 232 232;
  --color-light: 232 232 232;
  --color-dark: 214 214 214;

  --color-danger: 185 28 28; /*   bg-danger	 */
  --color-info: 37 99 235; /*     bg-info */
  --color-warning: 245 158 11; /* bg-amber-500 */
  --color-success: 22 163 74; /*  bg-green-600 */

  /* base jrl colours */
  --color-primary-1: 30 30 30;
  --color-primary-2: 255 255 255;
  --color-brand-1: 158 27 50;
  --color-brand-2: 0 90 43;

  --color-secondary-1: 82 82 82;
  --color-secondary-1-5: 111 111 111;
  --color-secondary-2: 140 140 140;
  --color-secondary-2-5: 168 168 168;
  --color-secondary-3: 196 196 196;
  --color-secondary-3-5: 214 214 214;
  --color-secondary-4: 232 232 232;

  --color-tertiary-warm-1: 108 70 70;
  --color-tertiary-warm-1-5: 149 101 81;
  --color-tertiary-warm-2: 189 132 91;
  --color-tertiary-warm-2-5: 193 153 109;
  --color-tertiary-warm-3: 197 173 126;
  --color-tertiary-warm-3-5: 212 193 158;
  --color-tertiary-warm-4: 226 213 190;

  --color-tertiary-cool-1: 64 70 92;
  --color-tertiary-cool-1-5: 78 95 118;
  --color-tertiary-cool-2: 91 120 143;
  --color-tertiary-cool-2-5: 120 147 158;
  --color-tertiary-cool-3: 149 173 172;
  --color-tertiary-cool-3-5: 177 194 195;
  --color-tertiary-cool-4: 205 215 217;

  --color-disabled: 239 239 239;
}

@layer base {
  @font-face {
    font-family: "JLREmericWeb-ExtraLight";
    src: local("jlr"),
      url("../../public/fonts/JLREmericWeb-ExtraLight.woff") format("woff");
  }

  @font-face {
    font-family: "JLREmericWeb-Regular";
    src: url("../../public/fonts/JLREmericWeb-Regular.woff") format("woff");
  }

  @font-face {
    font-family: "JLREmericWeb-SemiBold";
    src: url("../../public/fonts/JLREmericWeb-SemiBold.woff") format("woff");
  }

  html {
    font-family: JLREmericWeb-Regular !important;
  }
  @import "themes/theme2.css";
  @import "themes/theme3.css";
  @import "themes/theme4.css";
}

/* adding this here until I work out how to style the focussed on the @mention text area.*/
textarea:focus {
  outline: 1px solid #8c8c8c;
  outline-offset: -1px;
}

/* SVG classes */
svg {
  display: block; /* To remove any extra space below inline elements like <svg> */
}
svg *:focus {
  outline: none;
}

svg .task .info {
  display: none;
}

svg .task:hover .info {
  display: block;
}

/* dark */
html[data-theme='theme4'] {
  --color-primary: 111 111 111;
  --color-secondary: 30 30 30;
  --color-trans: rgba(196, 196, 196, 0.8);
  --color-tertiary: 82 82 82;
  --color-heading: 232 232 232;
  --color-title: 64 64 64;
  --color-typography-1: 30 30 30;
  --color-typography-2: 232 232 232;
  --color-light: 168 168 168;
  --color-dark: 140 140 140;
}


.editor-wrapper {
    background: grey;
    padding: 1px;
}

.editor {
    background: white;
    padding: 1rem;
    max-height: 400px;
}

.editor .public-DraftStyleDefault-block {
    margin: 0px !important;
    line-height: normal !important;
}

.editor h1 {
    font-size: 1.35rem;
    font-weight: bold;
}
.editor h2 {
    font-size: 1.3rem;
    font-weight: bold;
 
}
.editor h3 {
    font-size: 1.25rem;
    font-weight: bold;
}
.editor h4 {
    font-size: 1.2rem;
    font-weight: semi-bold;
}
.editor h4 {
    font-size: 1.15rem;
    font-weight: semi-bold;
    color: red;
}
.editor h6, .editor h5 {
    font-size: 1.1rem;
    font-weight: semi-bold;
}
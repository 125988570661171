/* warm */
html[data-theme='theme2'] {
  --color-primary: 108 70 70;
  --color-secondary: 255 255 255;
  --color-tertiary: 232 232 232;
  --color-heading: 108 70 70;
  --color-title: 108 70 70;
  --color-typography-1: 30 30 30;
  --color-typography-2: 232 232 232;
  --color-light: 232 232 232;
  --color-dark: 214 214 214;
}

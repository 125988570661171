/* light */
html[data-theme="theme3"] {
  --color-primary: 196 196 196;
  --color-secondary: 140 140 140;
  --color-trans: rgba(140, 140, 140, 0.9);
  --color-tertiary: 232 232 232;
  --color-heading: 255 255 255;
  --color-title: 255 255 255;
  --color-typography-1: 82 82 82;
  --color-typography-2: 82 82 82;
  --color-light: 255 255 255;
  --color-dark: 232 232 232;
}

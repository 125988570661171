@import url('../../styles/Global.css');

#container {
  top: 50%;
  left: 50%;
  position: absolute;
  display: block;
  transform: translate(-50%, -50%);
  z-index: 1;
}

#logoWrapper {
  border-radius: 20px;
  background: rgb(var(--secondary));
  position: relative;
  height: 180px;
  width: 420px;
  margin: -20px 0 auto;
}

#logoWrapper.run-animation {
  animation: logo-wrapper 2s ease forwards 0s;
}

/* Here */

#appHeadline {
  position: absolute;
  width: 400px;
  top: calc(50% - 20px);
  left: calc(50% + 10px);
  transform: translate(-50%, -50%);
  min-width: 400px;
  min-height: 505px;
}
#appHeadline path {
  stroke: rgb(var(--color-title));
  stroke-width: 3;
  animation-delay: 1;
  /* transform: translateY(-200%); */
}
#appHeadline path:nth-child(1) {
  stroke-dasharray: 383.52001953125;
  stroke-dashoffset: 383.52001953125;
}
#appHeadline.run-animation path:nth-child(1) {
  animation:
    line-appHeadline 2s ease forwards 1.4s,
    move-appHeadline 2s ease forwards 0.8999999999999999s,
    fill-appHeadline 2s ease forwards 2.4s;
}
#appHeadline path:nth-child(2) {
  stroke-dasharray: 526.8080444335938;
  stroke-dashoffset: 526.8080444335938;
}
#appHeadline.run-animation path:nth-child(2) {
  animation: line-appHeadline 2s ease forwards 1.1s,
  move-appHeadline 2s ease forwards 0.6s,
  fill-appHeadline 2s ease forwards 2.1s;
}
#appHeadline path:nth-child(3) {
  stroke-dasharray: 579.8994750976562;
  stroke-dashoffset: 579.8994750976562;
}
#appHeadline.run-animation path:nth-child(3) {
  animation:
  line-appHeadline 2s ease forwards 0.8s,
    move-appHeadline 2s ease forwards 0.3s,
    fill-appHeadline 2s ease forwards 1.8s;
}
#appHeadline path:nth-child(4) {
  stroke-dasharray: 611.3200073242188;
  stroke-dashoffset: 611.3200073242188;
}
#appHeadline.run-animation path:nth-child(4) {
  animation: line-appHeadline 2s ease forwards 0.5s,
    move-appHeadline 2s ease forwards 0s, fill-appHeadline 2s ease forwards 1.5s;
}
@keyframes line-appHeadline {
  to {
    stroke-dashoffset: 0;
  }
}
@keyframes fill-appHeadline {
  from {
    fill: transparent;
  }
  to {
    fill: rgb(var(--color-title));
  }
}
@keyframes move-appHeadline {
  to {
    transform: translateY(0px);
  }
}
@keyframes logo-wrapper {
  from {
    background-color: transparent;
  }

  to {
    background-color: var(--color-trans);
    /* opacity: 0.9; */
  }
}

#appStrapline {
  position: absolute;
  width: 390px;
  bottom: -10px;
  left: calc(50% + 4px);
  transform: translate(-50%, -50%);
  min-width: 390px;
  min-height: 35px;
}
#appStrapline.run-animation {
  animation: move-appStrapline 2s ease forwards,
    fill-appStrapline 0.5s ease forwards 3s;
}
#appStrapline path {
  stroke: rgb(var(--color-title));
  stroke-width: 1;
}
#appStrapline path:nth-child(1) {
  stroke-dasharray: 115.072021484375;
  stroke-dashoffset: 115.072021484375;
}
#appStrapline.run-animation path:nth-child(19) {
  animation: line-appStrapline 3s ease forwards 0.05263157894736842s;
}
#appStrapline path:nth-child(2) {
  stroke-dasharray: 91.3919677734375;
  stroke-dashoffset: 91.3919677734375;
}
#appStrapline.run-animation path:nth-child(18) {
  animation: line-appStrapline 3s ease forwards 0.10526315789473684s;
}
#appStrapline path:nth-child(3) {
  stroke-dasharray: 51.26397705078125;
  stroke-dashoffset: 51.26397705078125;
}
#appStrapline.run-animation path:nth-child(17) {
  animation: line-appStrapline 3s ease forwards 0.15789473684210525s;
}
#appStrapline path:nth-child(4) {
  stroke-dasharray: 72.1920166015625;
  stroke-dashoffset: 72.1920166015625;
}
#appStrapline.run-animation path:nth-child(16) {
  animation: line-appStrapline 3s ease forwards 0.21052631578947367s;
}
#appStrapline path:nth-child(5) {
  stroke-dasharray: 124.28741455078125;
  stroke-dashoffset: 124.28741455078125;
}
#appStrapline.run-animation path:nth-child(15) {
  animation: line-appStrapline 3s ease forwards 0.2631578947368421s;
}
#appStrapline path:nth-child(6) {
  stroke-dasharray: 109.1582260131836;
  stroke-dashoffset: 109.1582260131836;
}
#appStrapline.run-animation path:nth-child(14) {
  animation: line-appStrapline 3s ease forwards 0.3157894736842105s;
}
#appStrapline path:nth-child(7) {
  stroke-dasharray: 51.2640380859375;
  stroke-dashoffset: 51.2640380859375;
}
#appStrapline.run-animation path:nth-child(13) {
  animation: line-appStrapline 3s ease forwards 0.3684210526315789s;
}
#appStrapline path:nth-child(8) {
  stroke-dasharray: 79.552001953125;
  stroke-dashoffset: 79.552001953125;
}
#appStrapline.run-animation path:nth-child(12) {
  animation: line-appStrapline 3s ease forwards 0.42105263157894735s;
}
#appStrapline path:nth-child(9) {
  stroke-dasharray: 98.62862396240234;
  stroke-dashoffset: 98.62862396240234;
}
#appStrapline.run-animation path:nth-child(11) {
  animation: line-appStrapline 3s ease forwards 0.47368421052631576s;
}
#appStrapline path:nth-child(10) {
  stroke-dasharray: 104.40635681152344;
  stroke-dashoffset: 104.40635681152344;
}
#appStrapline.run-animation path:nth-child(10) {
  animation: line-appStrapline 3s ease forwards 0.5263157894736842s;
}
#appStrapline path:nth-child(11) {
  stroke-dasharray: 116.40625;
  stroke-dashoffset: 116.40625;
}
#appStrapline.run-animation path:nth-child(9) {
  animation: line-appStrapline 3s ease forwards 0.5789473684210527s;
}
#appStrapline path:nth-child(12) {
  stroke-dasharray: 109.1578598022461;
  stroke-dashoffset: 109.1578598022461;
}
#appStrapline.run-animation path:nth-child(8) {
  animation: line-appStrapline 3s ease forwards 0.631578947368421s;
}
#appStrapline path:nth-child(13) {
  stroke-dasharray: 116.91876220703125;
  stroke-dashoffset: 116.91876220703125;
}
#appStrapline.run-animation path:nth-child(7) {
  animation: line-appStrapline 3s ease forwards 0.6842105263157895s;
}
#appStrapline path:nth-child(14) {
  stroke-dasharray: 99.1643295288086;
  stroke-dashoffset: 99.1643295288086;
}
#appStrapline.run-animation path:nth-child(6) {
  animation: line-appStrapline 3s ease forwards 0.7368421052631579s;
}
#appStrapline path:nth-child(15) {
  stroke-dasharray: 91.39199829101562;
  stroke-dashoffset: 91.39199829101562;
}
#appStrapline.run-animation path:nth-child(5) {
  animation: line-appStrapline 3s ease forwards 0.7894736842105263s;
}
#appStrapline path:nth-child(16) {
  stroke-dasharray: 109.15662384033203;
  stroke-dashoffset: 109.15662384033203;
}
#appStrapline.run-animation path:nth-child(4) {
  animation: line-appStrapline 3s ease forwards 0.8421052631578947s;
}
#appStrapline path:nth-child(17) {
  stroke-dasharray: 116.40611267089844;
  stroke-dashoffset: 116.40611267089844;
}
#appStrapline.run-animation path:nth-child(3) {
  animation: line-appStrapline 3s ease forwards 0.8947368421052632s;
}
#appStrapline path:nth-child(18) {
  stroke-dasharray: 124.28752136230469;
  stroke-dashoffset: 124.28752136230469;
}
#appStrapline.run-animation path:nth-child(2) {
  animation: line-appStrapline 3s ease forwards 0.9473684210526315s;
}
#appStrapline path:nth-child(19) {
  stroke-dasharray: 115.07231140136719;
  stroke-dashoffset: 115.07231140136719;
}
#appStrapline.run-animation path:nth-child(1) {
  animation: line-appStrapline 3s ease forwards 1s;
}
@keyframes line-appStrapline {
  to {
    stroke-dashoffset: 0;
  }
}
@keyframes fill-appStrapline {
  from {
    fill: transparent;
  }
  to {
    fill: rgb(var(--color-title));
  }
}
@keyframes move-appStrapline {
  to {
    bottom: -10px;
  }
}
